// 会计和记账
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;

//用户上传凭证保存接口
export const UploadCredentials = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/account/uploadCredentials`,
    data: params
  })
}
//用户上传凭证页列表查询接口
export const credentialsSelectList = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/account/credentialsSelectList`,
    params: data
  })
}
// 会计主管分配会计-用户列表查询接口
export const allocationAccountting = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/accountting/allocationAccountting`,
    params: data
  })
}
// 会计主管查询当前部门下的会计
export const selectAccounting = (data) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/accountting/selectAccounting`,
    params: data
  })
}
// 会计主管分配会计-修改接口/admin/accountting/updateAccount
export const updateAccount = (data) => {
  return service({
    url: `${baseUrl}/admin/accountting/updateAccount`,
    method: 'post',
    data: data
  })
}
// 会计管理财务报表-上传保存接口
export const uploadExport = (data) => {
  return service({
    url: `${baseUrl}/trade-website/account/uploadExport`,
    method: 'post',
    data: data
  })
}
//会计管理财务报表-用户财报查询接口
export const selectExport = (data) => {
  return service({
    url: `${baseUrl}/trade-website/account/selectExport`,
    method: 'get',
    params: data
  })
}

// 会计查看凭证-列表查询接口

export const accountingSelect = (data) => {
  return service({
    url: `${baseUrl}/trade-website/account/accountingSelect`,
    method: 'get',
    params: data
  })
}

// 会计管理财务报表-用户列表接口
export const selectUserExport = (data) => {
  return service({
    url: `${baseUrl}/admin/accountting/selectUserExport`,
    method: 'get',
    params: data
  })
}
//文件下载
export const download = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/file/get`,
    params: params,
    responseType: 'blob'
  })
}
// 凭证文件批量下载接口
export const batchDownload = (data) => {
  return service({
    url: `${baseUrl}/trade-website/account/batchDownload`,
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}

