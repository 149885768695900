//用户分配
export const UserAssignment = [{
    prop: "userName",
    label: "用户名",
    width: 100,

  },

  {
    prop: "areaName",
    label: "地址",
  },
  {
    prop: "accountting",
    label: "负责人",

  },
  {
    prop: "allocateTime",
    label: "分配时间",

  },
  {
    prop: "operation",
    label: "操作",
    width: 140,
    template: true,
  },
]
//在线记账
export const incomeAndExpense = [{
    prop: "fileType",
    label: "收支类别",
    template: true,
  },
  {
    prop: "files",
    label: "图片预览",
    template: true,
  },

  {
    prop: "createTime",
    label: "上传时间",
    template: true,
  },
  {
    prop: "remark",
    label: "备注",

  },
  {
    prop: "userName",
    label: "上传人",

  },
  {
    prop: "phone",
    label: "联系方式",

  },

]
//财务报表上传
export const uploadFinancial = [{
  prop: "userName",
  label: "用户名",
  width: 100,
}, {
  prop: "areaName",
  label: "地址",
}, {
  prop: "createTime",
  label: "最新上传时间",
}, {
  prop: "title",
  label: "最新财报",

}, {
  prop: "accounting",
  label: "操作人",
  width: 100,
}, {
  prop: "operation",
  label: "操作",
  width: 140,
  template: true,
}, ]
